const slideUp = (target, opacity = true, duration = 200, callback) => {
    target.style.transitionProperty = "height, margin, padding, opacity";
    target.style.transitionDuration = duration + "ms";
    target.style.transitionTimingFunction = "ease-in-out";
    target.style.boxSizing = "border-box";
    target.style.height = target.offsetHeight + "px";
    target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    opacity && (target.style.opacity = 0);

    window.setTimeout(() => {
        target.style.removeProperty("height");
        target.style.removeProperty("padding-top");
        target.style.removeProperty("padding-bottom");
        target.style.removeProperty("margin-top");
        target.style.removeProperty("margin-bottom");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
        target.style.removeProperty("transition-timing-function");
        opacity && target.style.removeProperty("opacity");
        target.style.display = "none";
        if(callback) callback();
    }, duration);
};

const slideDown = (target, opacity = true, duration = 200, callback) => {
    target.style.removeProperty("display");
    let display = window.getComputedStyle(target).display;

    if (display === "none") display = "block";

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    opacity && (target.style.opacity = 0);
    target.offsetHeight;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding, opacity";
    target.style.transitionTimingFunction = "ease-in-out";
    target.style.transitionDuration = duration + "ms";
    target.style.height = height + "px";
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    opacity && target.style.removeProperty("opacity");

    window.setTimeout(() => {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
        target.style.removeProperty("transition-property");
        target.style.removeProperty("transition-timing-function");
        if(callback) callback();
    }, duration);
};

const slideToggle = (target, opacity = true, duration = 200, callback) => {
    if (window.getComputedStyle(target).display === "none") {
        return slideDown(target, opacity, duration, callback);
    } else {
        return slideUp(target, opacity, duration, callback);
    }
};

export { slideUp, slideDown, slideToggle };
