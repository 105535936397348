import { slideUp, slideDown } from "./helpers";

export default function() {
    const groups = Array.from(document.querySelectorAll(".accordion"));

    groups.forEach((group) => {
        const groupHeader = group.querySelector(".accordion__title");
        const groupBody = group.querySelector(".accordion__text");

        if (groupHeader && groupBody) {
            groupHeader.addEventListener("click", () => {
                if (group.classList.contains("closed")) {
                    group.classList.remove("closed");
                    // group.classList.remove("closing");
                    slideDown(groupBody, true, 200);
                } else {
                    // Indicate that we are closing our accordion
                    group.classList.add("closing");
                    slideUp(groupBody, true, 200, () => {
                        // Accordion finished closing
                        group.classList.remove("closing");
                        // Accordion is now closed
                        group.classList.add("closed");
                    });
                }
            });
        }
    });
}
