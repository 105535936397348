function downloadFile(url) {
    // Only downloads if the same url is used (e.g. puckolo.test !== puckolo.test:3000)
    let a = document.createElement("a");
    a.href = url;

    if (typeof a.download != "undefined") {
        a.download = url.split("/").pop();
    } else {
        a.target = "_blank";
    }

    document.body.appendChild(a);
    console.log(a);
    a.click();

    document.body.removeChild(a);
}

export default function () {
    const forms = Array.from(document.querySelectorAll(".form[data-id]"));

    forms.forEach((form) => {
        form.addEventListener("freeform-ready", function (event) {
            const freeform = event.target.freeform;

            freeform.addOnSuccessfulAjaxSubmit((evt, form, response) => {
                const download = form.dataset.downloadUrl || null;

                if (download) {
                    downloadFile(download);
                }
            });

            // freeform.addOnFailedAjaxSubmit(function (event, form, response) {
            //     var errors = response.errors;
            //     var btn = form.querySelector(".form__submit");
            //     btn.classList.remove("form__submit--loading");
            // });
        });
    });
}
