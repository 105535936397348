function getFeatureCollection(dealers) {
    let collection = {
        type: "FeatureCollection",
        features: dealers.map((dealer) => {
            return {
                type: "Feature",
                properties: {
                    title: dealer.title,
                    image: dealer.image,
                    url: dealer.url,
                    address: {
                        street: dealer.address.street,
                        number: dealer.address.number,
                        postcode: dealer.address.postcode,
                        city: dealer.address.city,
                    },
                },
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(dealer.lng, 2), parseFloat(dealer.lat, 2)],
                },
            };
        }),
    };

    return collection;
}

function getPopup(feature) {
    const { title, image, address, url } = feature.properties;
    const options = { offset: 25, closeButton: false };

    return new mapboxgl.Popup(options).setHTML(
        `   
            ${image ? `<img class="mapboxgl-popup-content__image" src="${image}">` : ``}
            <div class="mapboxgl-popup-content__body">
                <h3 class="mapboxgl-popup-content__title">${title}</h3>
                <div class="mapboxgl-popup-content__text">
                    <p>
                        ${address.street} ${address.number}<br>
                        ${address.postcode} ${address.city}                    
                    </p>
                </div>
                ${
                    url
                        ? `<a href="${url}" class="mapboxgl-popup-content__link" target="_blank" rel="noopener">${window.translations["openwebsite"]}</a>`
                        : ``
                }
            </div>
        `
    );
}

export default function () {
    // Is mapbox found?
    if (typeof mapboxgl == "undefined") {
        return;
    }

    const mapEl = document.getElementById("map");

    if (!mapEl) {
        return;
    }

    mapboxgl.accessToken = mapEl.dataset.mapboxAccesstoken;
    const bounds = new mapboxgl.LngLatBounds();

    const dealers = getFeatureCollection(window.dealers);

    const map = new mapboxgl.Map({
        container: mapEl, // container id
        style: "mapbox://styles/mapbox/streets-v11", // style URL
        center: [4.4699, 50.5039], // starting position [lng, lat]
        zoom: 7, // starting zoom
    });

    // Add markers
    dealers.features.forEach((dealer) => {
        // create a HTML element for each dealer
        var el = document.createElement("div");
        el.className = "marker";

        console.log(dealer);
        // make a marker for each dealer and add to the map
        new mapboxgl.Marker(el)
            .setLngLat(dealer.geometry.coordinates)
            .setPopup(getPopup(dealer))
            .addTo(map);

        // Push coordinates into bounds object
        bounds.extend(dealer.geometry.coordinates);
    });

    // Fit map based on our search results

    if (dealers.features.length) {
        map.fitBounds(bounds, {
            padding: 40,
            animate: false,
            maxZoom: 15,
        });
    }

    // Disable scroll zoom
    map.scrollZoom.disable();

    // Add navigation control so we can zoom in/out
    const controls = new mapboxgl.NavigationControl();
    map.addControl(controls, 'bottom-right');
}
