import "core-js/stable";
import "regenerator-runtime/runtime";
import "../sass/site.scss";
import dealers from "./dealers";
import form from "./form";
import header from "./header";
import lemonadeEntry from "./lemonade-entry";
import Rellax from "rellax";

header();
form();
lemonadeEntry();
dealers();

var relax = new Rellax('.rellax', {
    center: true
});