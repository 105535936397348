const header = document.querySelector(".header");
const hero = document.querySelector(".hero");

function scrollHeader() {
    if (header) {
        let headerHeight = header.offsetHeight;
        // let heroHeight = hero ? hero.offsetHeight : 0;
        let heroHeight = 0;
        let lastScrollTop = 0;
        let delta = 10;

        window.addEventListener("scroll", () => {
            scroll();
        });

        function scroll() {
            let st = window.scrollY || window.pageYOffset;

            if (Math.abs(lastScrollTop - st) <= delta) return; // Fixes IE11 bug

            if (st > lastScrollTop && st > Math.abs(heroHeight - headerHeight)) {
                header.classList.add("header--scrolled");
            } else {
                if (st < Math.abs(heroHeight - headerHeight)) {
                    header.classList.remove("header--scrolled");
                }
            }

            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }
    }
}

function toggleNav() {
    const navToggles = [...document.querySelectorAll("[data-nav-toggle]")];

    navToggles.forEach((toggle) => {
        toggle.addEventListener("click", (e) => {
            document.body.classList.toggle("nav-open");
        });
    });
}
export default function () {
    scrollHeader();
    toggleNav();
}
